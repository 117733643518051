<template>
  <div class="contact">
    <div class="describe">
      <div class="line" />
      <h2>联系我们</h2>
      <p>辽宁山水通途物联智能科技有限公司</p>
      <p>Liaoning Shanshui Tongtu IOT Intelligent Technology Co., Ltd</p>
      <div class="address info">
        <img src="./../../assets/images/address.png" alt="">
        <p>地址：辽宁省沈阳市浑南区上深沟860号国际软件园F8-B408</p>
      </div>
      <div class="phone info">
        <img src="./../../assets/images/phone.png" alt="">
        <p>客服热线：400-080-0988</p>
      </div>
    </div>
    <div class="map">
      <baidu-map class="bm-view" :zoom="18" :center="position">
        <!-- <bm-view style="width: 100%; height: 80vh"></bm-view> -->
        <bm-navigation />
        <bm-marker :position="position" @click="infoWindowOpen" />
        <bm-info-window
          :position="position"
          title="山水物联"
          :show="infoWindow.show"
          @open="infoWindowOpen"
          @close="infoWindowClose"
        >
          <a class="marker-link" :href="address" target="_blank">
            <p class="window" v-text="infoWindow.contents" />
          </a>
        </bm-info-window>
      </baidu-map>
      <!-- <amap :zoom="15" :center="position">
        <amap-marker
          :position="position"
          :label="{
            content: '辽宁省沈阳市浑南区上深沟860号国际软件园F8-B408',
            direction: 'bottom',
          }"
          draggable
        />
      </amap> -->
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      // position: [123.490424, 41.698082],
      address:
        'http://api.map.baidu.com/marker?location=41.698019,123.489771&title=山水物联&content=辽宁山水通途物联智能科技有限公司&output=html',
      position: { lng: 123.489771, lat: 41.698019 },
      infoWindow: {
        show: true,
        contents: '地址：辽宁山水通途物联智能科技有限公司'
      }
    }
  },
  methods: {
    infoWindowClose(e) {
      this.infoWindow.show = false
    },
    infoWindowOpen(e) {
      console.log(1)
      this.infoWindow.show = true
    }
  }
}
</script>

<style lang="scss">
@import "./../../assets/scss/base.scss";
.contact {
  .describe {
    color: #414141;
    font-size: 20px;
    .info {
      display: flex;
      align-items: center;
      img {
        margin-right: 20px;
        width: 30px;
        height: 30px;
      }
    }
    .phone {
      margin: 45px 0 80px 0;
    }
    p:nth-child(3) {
      line-height: 70px;
      font-size: 34px;
    }
    p:nth-child(4) {
      font-size: 24px;
      margin-bottom: 50px;
    }
  }
  .map {
    border: 1px solid #e1e1e1;
    width: 100%;
    height: 590px;
    margin-bottom: 200px;
    .bm-view {
      width: 100%;
      height: 100%;
    }
    .marker-link {
      color: #000;
      &:hover {
        color: #0062db;
      }
    }
  }
}
</style>
